import { CardHeader, Heading1, Size, Text } from '@cmctechnology/phoenix-stockbroking-web-design';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoBackIcon } from './GoBackIcon';

interface IIdentificationCardHeaderProps {
  onGoBack?: () => void;
}

export const IdentificationCardHeader: React.FC<IIdentificationCardHeaderProps> = ({ onGoBack }) => {
  const { t } = useTranslation();

  return (
    <CardHeader>
      {onGoBack && <GoBackIcon onClick={onGoBack} />}
      <Heading1>{t('Identification')}</Heading1>
      <Text size={Size.Large}>{t('For security, please enter one ID to verify who you are')}</Text>
    </CardHeader>
  );
};
