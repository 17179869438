import React from 'react';
import { CardContent, FormCol, FormGroup, FormGroupSeparator, FormRow, Heading3, Size, Text, Variant } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewIdentificationDriversLicence } from './ReviewIdentificationDriversLicence';
import { IdentificationType, IdentificationValidationStatus } from '../models/identification';
import { ReviewIdentificationPassport } from './ReviewIdentificationPassport';
import { ReviewIdentificationMedicare } from './ReviewIdentificationMedicare';
import { IdentificationReviewSection } from '../constants/validationConstants';
import { actions, IStore } from '../Store/Store';

export interface IReviewProfileIdentificationDetails {
  onIdentityEdit: (idType: IdentificationType) => void;
}

export const ReviewProfileIdentificationDetails: React.FC<IReviewProfileIdentificationDetails> = ({ onIdentityEdit }) => {
  const identificationDetails = useSelector((store: IStore) => store.remote.identificationDetails);
  const personalDetails = useSelector((store: IStore) => store.remote.profile.personalDetails);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onIdentificationSelect = (idType: IdentificationType) => {
    dispatch(actions.identificationValidationStatusUpdated(IdentificationValidationStatus.NotStarted));
    dispatch(actions.reviewSectionSelected(IdentificationReviewSection[idType!]));
    onIdentityEdit(idType);
  };

  return (
    <CardContent>
      <FormRow>
        <FormCol percentWidth={80}>
          <Heading3>{t('Identification')}</Heading3>
        </FormCol>
      </FormRow>

      {identificationDetails.status !== IdentificationValidationStatus.Passed && (
        <FormGroup>
          <FormRow>
            <FormCol percentWidth={100}>
              <Text size={Size.Large} variant={Variant.Error}>
                {t("You've selected to post your certified ID.")}
              </Text>
              <br />
              <Text size={Size.Large}>
                {t("We've sent you an email with the instructions. Once your ID has been verified, we can complete your application.")}
              </Text>
            </FormCol>
          </FormRow>
        </FormGroup>
      )}

      <>
        {identificationDetails.driversLicence && (
          <>
            <ReviewIdentificationDriversLicence
              driversLicence={identificationDetails.driversLicence}
              onEdit={() => onIdentificationSelect(IdentificationType.DriversLicence)}
            />
            {(identificationDetails.passport || identificationDetails.medicare) && <FormGroupSeparator />}
          </>
        )}
        {identificationDetails.passport && (
          <>
            <ReviewIdentificationPassport passport={identificationDetails.passport} onEdit={() => onIdentificationSelect(IdentificationType.Passport)} />
            {identificationDetails.medicare && <FormGroupSeparator />}
          </>
        )}
        {identificationDetails.medicare && (
          <ReviewIdentificationMedicare
            medicare={identificationDetails.medicare}
            personalDetails={personalDetails}
            onEdit={() => onIdentificationSelect(IdentificationType.Medicare)}
          />
        )}
      </>
    </CardContent>
  );
};
