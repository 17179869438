export enum TrackEventNames {
  ApplicationForm = `Application Form Submission (New)`
}

export enum ApplicationFormPaths {
  SelectAccountType = `SelectAccountType`,
  SelectPaymentMethod = `SelectPaymentMethod`,
  CreateLogIn = `CreateLogIn`,
  PersonalDetails = `PersonalDetails`,
  AddressDetails = `AddressDetails`,
  TaxDetails = `TaxDetails`,
  Employment = `Employment`,
  IdentityVerification = `IdentityVerification`,
  GlobalScreeningPepQuestions = `GlobalScreeningPepQuestions`,
  TermsAndConditions = `TermsAndConditions`,
  ApplicationSubmitted = `ApplicationSubmitted`
}
